import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons';

export default function ProjectLink({url}) {
  return (
    <a href={url} className="btn btn-secondary gap-1 d-inline-flex align-items-center" title="Podgląd strony" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faEye} />
      Zobacz na żywo
    </a>
  )
}
