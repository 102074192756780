import React from "react";
import { graphql } from 'gatsby';
import Seo from '../components/Seo'
import Layout from "../components/Layout";
import ProjectLayout from "../components/projects/Layout";
import WorkRange from "../components/projects/WorkRange";

export default function Project({data}) {
    const project = data.allSitePage.edges[0].node.context;

    const tasks = (
        <WorkRange>
            { project.workRange.map( (value, key) => <li key={key}>{value}</li> ) }
        </WorkRange>
    );
    return (
        <>
        <Seo title={project.title} description={project.description} image={project.image} />
        <Layout title={project.title}>
            <ProjectLayout 
                title={project.subtitle}
                description={project.description}
                workRange={tasks}
                linkToProject={project.url}
                pictureSrc={project.image}
                gallery={project.gallery}
            />
        </Layout>
        </>
    )
}

export const query = graphql`
    query($path: String!) {
        allSitePage(filter: { path: { eq: $path } } ) {
            edges {
                node {
                    context {
                        slug
                        category
                        title
                        subtitle
                        description
                        url
                        image
                        workRange
                        gallery
                    }
                }
            }
        }
    }
`