import React from 'react'

/**
 * 
 * @param {liItems} children 
 * @returns Work range with tasks wrapped in ul list
 */
export default function WorkRange({children}) {
  return (
    <div>
        <h5 className="mb-3">Zakres pracy</h5>
        <ul className="work-range">
            {children}
        </ul>    
    </div>
  )
}
