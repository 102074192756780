import React from 'react';
import {Link} from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft, faRocket } from '@fortawesome/free-solid-svg-icons';
import SectionTitle from '../SectionTitle';
import ProjectLink from "../../components/projects/ProjectLink";
import Picture from "../../components/projects/Picture";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

export default function Layout({title, description, workRange, linkToProject, pictureSrc, gallery}) {
  return (
    <>
    <section className="section project">
        <div className="container" data-aos="fade-up">

            <Link to={`/projekty`} className="section__back-btn mb-5 gap-1" title="Wróć do poprzedniej strony">
                <FontAwesomeIcon icon={faChevronCircleLeft} />
                Powrót
            </Link>

            {title && <SectionTitle title={title} />}
            <div className="row flex-wrap-reverse flex-lg-nowrap gy-5">
                <div className="col-12 col-lg-6">
                    {description && <p>{description}</p>}
                    {workRange && workRange}
                    {linkToProject && <ProjectLink url={linkToProject} />}
                </div>
                <div className="col-12 col-lg-6">
                    <Zoom>
                        {pictureSrc && <Picture src={pictureSrc} alt={title} />}
                    </Zoom>
                </div>
            </div>
            <div className="row mt-5 g-3">
                {gallery && gallery.map((photo) => {
                    return (
                        <div className="col-12 col-md-6" key={photo}>
                            <Zoom>
                                <img className="img-fluid lazy" src="/pixel.gif" data-src={photo} width="640" height="380" alt="" />
                            </Zoom>
                        </div>
                    )    
                })}
            </div>
        </div>
    </section>
    <section className="section section--with-bg pb-0 pt-5">
        <div className="container text-center">
            <h3>Potrzebujesz strony internetowej?</h3>
            <p className="lead m-0">Wypełnij brief, a przygotuję dla Ciebie indywidualną wycenę.</p>
            <Link to="/brief" className="btn btn-secondary btn--with-icon mt-3" title="Wycena">
                <FontAwesomeIcon icon={faRocket} />
                Wypełnij brief
            </Link>
        </div>
    </section>
    </>
  )
}
